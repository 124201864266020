import { Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import PropTypes from 'prop-types';

import { SectionHeader, TypedText } from '@/src/components/molecules';
import { HeroShaped } from '@/src/components/organisms';

const useStyles = makeStyles(theme => ({
  fontWeight600: {
    fontWeight: 600,
  },
  leftSideContent: {
    '& .section-header__cta-container': {
      [theme.breakpoints.down('xs')]: {
        flexDirection: 'column',
        '& .section-header__cta-item-wrapper': {
          width: '100%',
          '&:last-child': {
            marginLeft: 0,
            marginTop: theme.spacing(1),
          },
          '& .MuiButtonBase-root': {
            width: '100%',
          },
        },
      },
    },
  },
  heroShaped: {
    margin: 0,
    padding: 0,
    height: 'auto',
    '& .hero-shaped__image': {
      backgroundColor: theme.palette.alternate.main,
    },
    [theme.breakpoints.down('sm')]: {
      '& .hero-shaped__image': {
        position: 'relative',
      },
      '& .hero-shaped__wrapper': {
        flexDirection: 'column',
      },
    },
  },
  imageAnimation: {
    background: `url("/images/partners/partners_list.jpg")`,
    backgroundRepeat: 'repeat',
    backgroundAttachment: 'scroll',
    backgroundSize: '300px auto',
    animation: `$slideshow 15s linear infinite`,
    width: '400%',
    height: '400%',
    backgroundColor: theme.palette.alternate.light,
    top: '-25%',
    left: '-100%',
    position: 'absolute',
    [theme.breakpoints.up('sm')]: {
      backgroundSize: '800px auto',
    },
  },
  '@keyframes slideshow': {
    '0%': {
      transform: 'rotate(-13deg) translateY(-25%)',
    },
    '100%': {
      transform: 'rotate(-13deg) translateY(-60%)',
    },
  },
}));

const Stats = props => {
  const { className, ...rest } = props;

  const classes = useStyles();

  const title = (
    <Typography variant="h2" component="span" className={classes.fontWeight600}>
      Mais de
      <br />
      <TypedText
        component="span"
        variant="h2"
        color="primary"
        className={classes.fontWeight600}
        typedProps={{
          strings: ['1.100 escolas.', '500.000 famílias.', '150 vendedores.'],
          typeSpeed: 50,
          loop: true,
        }}
      />
    </Typography>
  );

  const leftSideContent = (
    <SectionHeader
      title={title}
      subtitle="Mais de 1.100 escolas, 500.000 famílias e 150 vendedores do setor educacional já utilizam nossa solução. Conheça alguma delas:"
      align="left"
      titleProps={{
        variant: 'h2',
        color: 'textPrimary',
      }}
      data-aos="fade-right"
      disableGutter
      className={classes.leftSideContent}
    />
  );

  return (
    <div className={className} {...rest}>
      <HeroShaped
        className={classes.heroShaped}
        leftSide={leftSideContent}
        rightSide={<div className={classes.imageAnimation} />}
      />
    </div>
  );
};

Stats.propTypes = {
  className: PropTypes.string,
};

export default Stats;
